.DocumentListFilename {
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
	user-select: none;
}

.DocumentViewTopContainer {
	text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}

.DocumentViewFilename {
	font-size: 20px;
	font-weight: bold;
}

.DocumentViewFileText {
	font-size: 16px;
	align-self: left;
}

.DocumentListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.DocumentListToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.DocumentListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.DocumentListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.DocumentListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.DocumentListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.DocumentListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.DocumentViewToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.DocumentViewToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.DocumentViewToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.DocumentViewToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.DocumentViewToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.DocumentViewToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.DocumentViewToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.DocumentEditTextInput {
	font-size: 16px;
	width: 95vw;
	//height: 500px;
}

.DocumentEditTextInput:focus {
    //outline: none;
}

.DocumentEditQuestionsInput {
	font-size: 16px;
	width: 95vw;
	padding: 5px;
}

.DocumentNewFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.DocumentNewLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.DocumentNewInput {
	border: 0px;
	font-size: 16px;
	width: 650px;
}

.DocumentNewInput:focus {
    outline: none;
}

