.QuestionSampleListTopContainer {
    padding-left: 10px;
    padding-right: 10px;
}

.QuestionSampleListQuestionContainer {
	display: flex-row;
}

.QuestionSampleListQuestionText {
    display: table-cell;
	font-size: 16px;
    padding: 5px;
	text-align: center;
}

.QuestionSampleListEditIcon {
    display: table-cell;
	font-size: 16px;
    padding: 5px;
    width: 10px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.QuestionSampleNewFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.QuestionSampleNewQuestionSampleLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.QuestionSampleNewQuestionSampleInput {
	border: 0px;
	font-size: 16px;
	width: 650px;
}

.QuestionSampleNewQuestionSampleInput:focus {
    outline: none;
}

.QuestionSampleEditFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.QuestionSampleEditQuestionSampleLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.QuestionSampleEditQuestionSampleInput {
	border: 0px;
	font-size: 16px;
	width: 650px;
}

.QuestionSampleEditQuestionSampleInput:focus {
    outline: none;
}

.QuestionSampleListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.QuestionSampleListToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.QuestionSampleListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.QuestionSampleListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.QuestionSampleListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.QuestionSampleListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.QuestionSampleListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.QuestionSampleTitleContainer {
}

.QuestionSampleTitleSpanSelected {
	border-width: 1px;
	border-style: solid;
	border-color: black;
	border-radius: 25px;
	background: #eaeaea;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 2px;
	margin-right: 5px;
	height: 50px;
	cursor: pointer;
	user-select: none;
}

.QuestionSampleTitleSpan {
	border-radius: 25px;
	background: #eaeaea;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 2px;
	margin-right: 5px;
	height: 50px;
	cursor: pointer;
	user-select: none;
}

.QuestionSampleTitleSpan:hover {
  	background: #d2d2d2;
}

