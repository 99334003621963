.QuestionLogListTopContainer {
    padding-left: 10px;
    padding-right: 10px;
}

.QuestionLogListTooltip {
	width: 500px;
}

.QuestionLogListQuestionContainer {
	display: flex-row;
}

.QuestionLogListQuestionText {
    display: table-cell;
	font-size: 16px;
    padding: 5px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.QuestionLogListCreatedHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 15vw;
	text-align: left;
}

.QuestionLogListCreatedItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.QuestionLogListModelHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 15vw;
	text-align: left;
}

.QuestionLogListModelItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.QuestionLogListQuestionTextHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 50vw;
	text-align: left;
}

.QuestionLogListQuestionTextItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.QuestionLogListTokensHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 10vw;
	text-align: left;
}

.QuestionLogListTokensItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.QuestionLogListChannelHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 10vw;
	text-align: left;
}

.QuestionLogListChannelItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.QuestionLogListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.QuestionLogListToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.QuestionLogListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.QuestionLogListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.QuestionLogListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.QuestionLogListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.QuestionLogListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.QuestionLogViewTopContainer {
    padding-left: 10px;
    padding-right: 10px;
}

.QuestionLogViewText {
	font-size: 16px;
}

.QuestionSampleReorderTopContainer {
	display: flex;
    margin-left: auto;
    margin-right: auto;
	align-items: center;
	justify-content: center;
}

