.SelectModelTopContainer {
    padding-left: 10px;
    padding-right: 10px;
}

.SelectModelQuestionText {
	font-size: 16px;
    padding: 10px;
	cursor: pointer;
	user-select: none;
}

.ModelListTopContainer {
    margin-left: auto;
    margin-right: auto;
	justify-content: center;
}

.ModelListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.ModelListToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.ModelListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.ModelListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.ModelListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.ModelListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.ModelListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.ModelNewFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.ModelNewLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.ModelNewInput {
	border: 0px;
	font-size: 16px;
	width: 650px;
}

.ModelNewInput:focus {
    outline: none;
}

.ModelListOneModelContainer {
    margin-left: auto;
    margin-right: auto;
	display: flex;
	flex-direction: row;
	width: fit-content;
}

.ModelListModelName {
	font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
	cursor: pointer;
	user-select: none;
}

.ModelListCopyIcon {
    margin-left: 5px;
    margin-right: 5px;
	margin-top: 3px;
	margin-bottom: 3px;
	cursor: pointer;
	user-select: none;
}

.ModelCopyFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.ModelCopyLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.ModelCopyInput {
	border: 0px;
	font-size: 16px;
	width: 650px;
}

.ModelCopyInput:focus {
    outline: none;
}

.ModelFromWebSiteListTopContainer {
    padding-left: 10px;
    padding-right: 10px;
}

.ModelFromWebSiteListItemContainer {
}

.ModelFromWebSiteListSubmittedHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 15vw;
	text-align: left;
}

.ModelFromWebSiteListSubmittedItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.ModelFromWebSiteListWebsiteHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 20vw;
	text-align: left;
}

.ModelFromWebSiteListWebsiteItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.ModelFromWebSiteListModelNameHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 20vw;
	text-align: left;
}

.ModelFromWebSiteListModelNameItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.ModelFromWebSiteListStatusHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 10vw;
	text-align: left;
}

.ModelFromWebSiteListStatusItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.ModelFromWebSiteListUserHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 20vw;
	text-align: left;
}

.ModelFromWebSiteListUserItem {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.ModelFromWebSiteViewTopContainer {
	font-size: 16px;
    margin-left: 15px;
    padding: 5px;
	text-align: left;
}

.ModelFromWebSiteViewFileContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.ModelFromWebSiteViewSearch {
	font-size: 14px;
	font-style: italic;
	margin-bottom: 5px;
}

.ModelFromWebSiteViewFileIcon {
	margin-top: 2px;
	width: 22px;
	user-select: none;
}

.ModelFromWebSiteViewFileText {
	width: 97vw;
	user-select: none;
}

.ModelFromWebSiteViewFileAnswer {
	margin-bottom: 10px;
}

.ModelFromWebSiteViewFileLargeText {
	font-size: 14px;
	font-style: italic;
	margin-bottom: 10px;
}

.ModelFromWebSiteViewFileShowHideLargeText {
	font-size: 14px;
	//font-style: italic;
	color: blue;
	user-select: none;
	margin-bottom: 10px;
}

.ModelNewFromWebSiteModelType {
	font-size: 16px;
	user-select: none;
	cursor: pointer;
}

