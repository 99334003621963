.UserLessonLogListTopContainer {
    padding-left: 10px;
    padding-right: 10px;
}

.UserLessonLogListText {
    display: table-cell;
	font-size: 16px;
    padding: 5px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.UserLessonLogListFullNameHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 30vw;
	text-align: left;
}

.UserLessonLogListFullNameItem {
	font-size: 16px;
    padding: 5px;
	cursor: default;
	text-align: left;
	cursor: pointer;
	user-select: none;
}

.UserLessonLogListDateTimeHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 15vw;
	text-align: left;
}

.UserLessonLogListDateTimeItem {
	font-size: 16px;
    padding: 5px;
	cursor: default;
	text-align: left;
	cursor: pointer;
	user-select: none;
}

.UserLessonLogViewTopContainer {
    padding-left: 10px;
    padding-right: 10px;
}

.UserLessonLogViewFullName {
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
	text-align: center;
}

.UserLessonLogViewCorrectHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 5vw;
	text-align: center;
}

.UserLessonLogViewCorrectItem {
	font-size: 16px;
	padding-top: 8px;
	padding-bottom: 0px;
	cursor: default;
	text-align: center;
}

.UserLessonLogViewPronounceItem {
	font-size: 16px;
	cursor: default;
	text-align: center;
}

.UserLessonLogViewQuestionHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 40vw;
	text-align: left;
}

.UserLessonLogViewQuestionItem {
	font-size: 16px;
    padding: 5px;
	cursor: default;
	text-align: left;
}

.UserLessonLogViewAnswerHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 30vw;
	text-align: left;
}

.UserLessonLogViewAnswerItem {
	font-size: 16px;
    padding: 5px;
	cursor: default;
	text-align: left;
}

.UserLessonLogViewLessonHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 10vw;
	text-align: left;
}

.UserLessonLogViewLessonItem {
	font-size: 16px;
    padding: 5px;
	cursor: default;
	text-align: left;
}

.UserLessonLogViewDateTimeHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 10vw;
	text-align: center;
}

.UserLessonLogViewDateTimeItem {
	font-size: 16px;
    padding: 5px;
	cursor: default;
	text-align: center;
}

