.ChangeLogListTopContainer {
    padding-left: 10px;
    padding-right: 10px;
}

.ChangeLogListTooltip {
	width: 500px;
}

.ChangeLogListQuestionContainer {
	display: flex-row;
}

.ChangeLogListQuestionText {
    display: table-cell;
	font-size: 16px;
    padding: 5px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.ChangeLogListCreatedHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 15vw;
	text-align: left;
}

.ChangeLogListCreatedItem {
	font-size: 16px;
    padding: 5px;
	cursor: default;
	text-align: left;
	user-select: none;
}

.ChangeLogListTitleHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 70vw;
	text-align: left;
}

.ChangeLogListTitleItem {
	font-size: 16px;
    padding: 5px;
	cursor: default;
	text-align: left;
	user-select: none;
}

.ChangeLogListFullNameHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
    width: 15vw;
	text-align: left;
}

.ChangeLogListFullNameItem {
	font-size: 16px;
    padding: 5px;
	cursor: default;
	text-align: left;
	user-select: none;
}

.ChangeLogListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.ChangeLogListToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.ChangeLogListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.ChangeLogListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.ChangeLogListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.ChangeLogListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.ChangeLogListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.ChangeLogViewTopContainer {
    padding-left: 10px;
    padding-right: 10px;
}

.ChangeLogViewText {
	font-size: 16px;
}

