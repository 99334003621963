.PageListTopContainer {
	text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}

.PageListFolderContainer {
	display: flex;
	flex-direction: row;	
}

.PageListFolder {
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
	margin-right: 5px;
}

.PageListIcon {
    margin-left: 5px;
    margin-right: 5px;
	margin-top: 10px;
	cursor: pointer;
	user-select: none;
}

.PageListSubfolder {
	font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
	margin-left: 30px;
	cursor: pointer;
	user-select: none;
}

.PageListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.PageListToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.PageListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.PageListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.PageListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.PageListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.PageListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.PageViewTopContainer {
    padding: 0px;
	margin: 0px;
	height: calc(100vh - 90px);
}

.PageEditTopContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #f5f5f5;
}

.PageEditSectionContainer {
	font-size: 16px;
	width: 70vw;
	height: 220px;
	border: 1px dashed;
	border-radius: 25px;
	border-color: black;
	background: white;
	margin-top: 5px;
	margin-bottom: 5px;
	cursor: pointer;
	user-select: none;
}

.PageEditSectionTypeContainer {
	display: flex;
	flex-direction: row;	
}

.PageEditSectionTypeTitle {
	font-size: 14px;
	padding: 2px;
	margin-left: 16px;
	color: grey;
	cursor: default;
	user-select: none;
}

.PageEditSectionTypeIcon {
	padding: 2px;
	margin-left: 2px;
	margin-top: 0px;
	cursor: pointer;
	user-select: none;
}

.PageEditSectionImage {
	height: 182px;
	object-fit: contain;
	padding: 0px;
}

.PageReorderImage {
	height: 80px;
	object-fit: contain;
	padding: 0px;
}

.PageEditSectionText {
	width: calc(70vw - 35px);
	height: 180px;
	margin-top: 3px;
	margin-bottom: 7px;
	margin-left: 17px;
	margin-right: 17px;
	text-align: left;
	overflow: auto;
}

.PageReorderText {
	width: calc(70vw - 35px);
	height: 80px;
	margin-top: 3px;
	margin-bottom: 7px;
	margin-left: 17px;
	margin-right: 17px;
	text-align: left;
	overflow: auto;
}

.PageEditSectionRichText {
	width: calc(70vw - 35px);
	height: 180px;
	margin-top: 3px;
	margin-bottom: 7px;
	margin-left: 4px;
	margin-right: 17px;
	text-align: left;
	overflow: auto;
}

.PageReorderRichText {
	width: calc(70vw - 35px);
	height: 80px;
	margin-top: 3px;
	margin-bottom: 7px;
	margin-left: 4px;
	margin-right: 17px;
	text-align: left;
	overflow: auto;
}

.PageEditSectionHtml {
	width: calc(70vw - 35px);
	height: 180px;
	object-fit: contain;
	margin-top: 0px;
	margin-bottom: 7px;
	margin-left: 4px;
	margin-right: 17px;
	overflow: auto;
}

.PageReorderHtml {
	width: calc(70vw - 35px);
	height: 80px;
	object-fit: contain;
	margin-top: 0px;
	margin-bottom: 7px;
	margin-left: 4px;
	margin-right: 17px;
	overflow: auto;
}

.PageEditSectionLink {
	width: calc(70vw - 35px);
	height: 180px;
	object-fit: contain;
	margin-top: 0px;
	margin-bottom: 7px;
	margin-left: 4px;
	margin-right: 17px;
	overflow: auto;
}

.PageReorderLink {
	width: calc(70vw - 35px);
	height: 80px;
	object-fit: contain;
	margin-top: 0px;
	margin-bottom: 7px;
	margin-left: 4px;
	margin-right: 17px;
	overflow: auto;
}

.PageEditSectionSingleChoice {
	width: calc(70vw - 35px);
	height: 180px;
	object-fit: contain;
	margin-top: 0px;
	margin-bottom: 7px;
	margin-left: 4px;
	margin-right: 17px;
	overflow: auto;
}

.PageReorderSingleChoice {
	width: calc(70vw - 35px);
	height: 60px;
	object-fit: contain;
	margin-top: 0px;
	margin-bottom: 7px;
	margin-left: 4px;
	margin-right: 17px;
	overflow: auto;
}

.PageReorderPronounce {
	width: calc(70vw - 35px);
	height: 60px;
	//object-fit: contain;
	margin-top: 0px;
	margin-bottom: 7px;
	margin-left: 4px;
	margin-right: 17px;
	overflow: auto;
}

.PageEditFooterContainer {
	display: flex;
	flex-direction: row;	
}

.PageEditFooterText {
	font-size: 16px;
	padding: 2px;
	margin: 0px;
}

.PageEditFooterLink {
	font-size: 16px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.PageEditHtmlTitle {
	font-size: 20px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
}

.PageEditHtmlImage {
	height: 182px;
	object-fit: contain;
	padding: 7px;
}

.PageEditHtmlDragContainer1 {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.PageEditHtmlDragContainer2 {
	width: 600px;
	text-align: center;
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	padding: 40px;
	cursor: pointer;
	user-select: none;
}

.PageEditTextTitle {
	font-size: 20px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
}

.PageEditTextInput {
	font-size: 16px;
	width: 95vw;
	//height: 500px;
}

.PageEditTextInput:focus {
    //outline: none;
}

.PageEditHtmlTitle {
	font-size: 20px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
}

.PageEditHtmlInput {
	border: 1px solid;
	margin-left: 5px;
	margin-right: 5px;
	font-size: 16px;
	width: 95vw;
}

.PageEditLinkContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.PageEditLinkTitle {
	font-size: 20px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
}

.PageEditLinkFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.PageEditLinkLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.PageEditLinkInput {
	border: 0px;
	font-size: 16px;
	width: 650px;
}

.PageEditLinkInput:focus {
    outline: none;
}

.PageEditSingleChoiceContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.PageEditSingleChoiceTitle {
	font-size: 20px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
}

.PageEditSingleChoiceFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.PageEditSingleChoiceLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.PageEditSingleChoiceInput {
	border: 0px;
	font-size: 16px;
	width: 650px;
}

.PageEditSingleChoiceInput:focus {
    outline: none;
}

.PageEditPronounceTitle {
	font-size: 20px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
}

.PageEditPronounceInput {
	border: 1px solid;
	margin-left: 5px;
	margin-right: 5px;
	font-size: 16px;
	width: 95vw;
}

.PageMainToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.PageMainToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.PageMainToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.PageMainToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.PageMainToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.PageMainToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.PageMainToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.PageNewFolderFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.PageNewFolderLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.PageNewFolderInput {
	border: 0px;
	font-size: 16px;
	width: 650px;
}

.PageNewFolderInput:focus {
    outline: none;
}

.PageNewSubfolderFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.PageNewSubfolderLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.PageNewSubfolderInput {
	border: 0px;
	font-size: 16px;
	width: 650px;
}

.PageNewSubfolderInput:focus {
    outline: none;
}

.PageRenameFolderFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.PageRenameFolderLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.PageRenameFolderInput {
	border: 0px;
	font-size: 16px;
	width: 650px;
}

.PageRenameFolderInput:focus {
    outline: none;
}
