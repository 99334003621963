.ChatTopContainer {
    padding: 0px;
	margin: 0px;
	height: calc(100vh - 70px);
}

.ChatQAContainerDesktop {
	height: calc(100vh - 200px);
	text-align: left;
    padding: 20px;
	border: 1px solid;
	border-color: #dddddd;
	overflow: auto;
}

.ChatQAContainerMobile {
	height: calc(100vh - 250px);
	text-align: left;
    padding: 20px;
	border: 1px solid;
	border-color: #dddddd;
	overflow: auto;
}

.ChatQuestionInput {
	font-size: 16px;
    padding: 5px;
	width: calc(100vw - 100px);
	border: 1px solid;
	border-color: #a9a9a9;
}

.ChatQuestionText {
	font-size: 16px;
	font-weight: bold;
}

.ChatAnswerText {
	font-size: 16px;
}

.ChatFilesContainer {
	display: flex;
	flex-direction: row;
}

.ChatFilename {
	font-size: 10px;
	padding: 2px;
	cursor: pointer;
	color: white;
	background-color: #aaaaaa;
	font-weight: bold;
	user-select: none;
}

.ChatFilesSpace {
	padding: 2px;
}
